import { createReducer, on } from '@ngrx/store';
import { DateRange } from 'src/app/types/date-range';
import {
  createErrorNetworkState,
  createLoadingNetworkState,
  createQuietNetworkState,
} from '../../modules/utils/helpers/network-entities';
import { LoadingState } from '../../modules/utils/types';
import { BloomingData } from '../../modules/report/types/blooming-data';
import { PlantReport } from '../../modules/report/types/plant-report';
import { ReportActions } from './report.actions';
import { TopListEntry } from 'src/app/modules/report/types/top-list-entry';
import { TopPlantEntry } from 'src/app/modules/report/types/top-plant-entry';
import { ResultActions } from './result.actions';
import { ReportSpot } from 'src/app/modules/report/types/report-spot';

export interface ReportState {
  reportListLoading: LoadingState;
  reportListCacheFlag: boolean;
  result: {};   // NOTE: look into this / what is it
  reports: PlantReport[];
  bloomingData: BloomingData[];
  bloomingDataCacheFlag: boolean;
  bloomingDataLoading: LoadingState;
  bloomingDataFilter: {
    plants: number[];
    dateRange: DateRange;
    year: number;
    locationId: number;
  };

  toplistData: TopListEntry[];
  toplistDataCacheFlag: boolean;
  toplistDataLoading: LoadingState;

  topplantsData: TopPlantEntry[];
  topplantsDataCacheFlag: boolean;
  topplantsDataLoading: LoadingState;

  spots: ReportSpot[];
  spotsCacheFlag: boolean;
  spotsDataLoading: LoadingState;

  activeSpot: ReportSpot;
}

// export interface State extends fromRoot.State {
//   report: ReportState;
// }

const initialState: ReportState = {
  reportListCacheFlag: false,
  reportListLoading: createQuietNetworkState(),
  result: {},
  reports: [],
  bloomingData: [],
  bloomingDataCacheFlag: false,
  bloomingDataLoading: createQuietNetworkState(),
  bloomingDataFilter: {
    plants: [],
    dateRange: {},
    locationId: null,   // No location

    // Year that is shown by default in the reporting view data section
    // either a year (e.g. 2025), or 0 for "all years"
    // year: new Date().getFullYear() - 1, // Last year
    // year: 0 // No year (all years)
    year: 0
  },
  toplistData: [],
  toplistDataCacheFlag: false,
  toplistDataLoading: createQuietNetworkState(),

  topplantsData: [],
  topplantsDataCacheFlag: false,
  topplantsDataLoading: createQuietNetworkState(),

  spots: [],
  spotsCacheFlag: false,
  spotsDataLoading: createQuietNetworkState(),
  activeSpot: null
};

export const ReportReducer = createReducer(
  initialState,

  on(ReportActions.reportSubmitted, (state, { report }) => ({
    ...state,
    result: report,
    error: false,
  })),
  on(ReportActions.reportSubmitOk, (state, { data }) => ({
    ...state,
    error: true,
    result: data,
  })),
  on(ReportActions.reportSubmitError, (state) => ({
    ...state,
    error: false,
  })),

  // get reports
  on(ReportActions.fetchReports, (state) => ({
    ...state,
    error: false,
    reportListLoading: createLoadingNetworkState(),
    reportListCacheFlag: false,
  })),
  on(ReportActions.getReportsSucess, (state, { reports }) => ({
    ...state,
    reports,
    reportListLoading: createQuietNetworkState(),
    reportListCacheFlag: true,
  })),
  on(ReportActions.getReportsError, (state, action) => ({
    ...state,
    error: true,
    reportListLoading: createErrorNetworkState(action.error),
  })),

  // delete reports
  on(ReportActions.deleteReportRequest, (state, { id }) => ({
    ...state,
    result: id,
    error: false,
  })),
  on(ReportActions.deleteReportSucess, (state, { result }) => ({
    ...state,
    result,
    error: true,
  })),
  on(ReportActions.deleteReportError, (state) => ({
    ...state,
    error: false,
  })),

  // live data (for maps and visualisations)
  on(ResultActions.fetchBloomingData, (state) => ({
    ...state,
    bloomingData: [],
    bloomingDataLoading: createLoadingNetworkState(),
    bloomingDataCacheFlag: false,
  })),
  on(ResultActions.fetchBloomingDataOk, (state, { items }) => ({
    ...state,
    bloomingData: items,
    bloomingDataLoading: createQuietNetworkState(),
    bloomingDataCacheFlag: true,
    bloomingDataFilter: {
      // Keep most of the filter settings
      ...state.bloomingDataFilter,

      // Reset plants filter
      plants: [],
    }
  })),
  on(ResultActions.fetchBloomingDataFail, (state, action) => ({
    ...state,
    bloomingData: [],
    bloomingDataLoading: createErrorNetworkState(action.error),
  })),
  on(ResultActions.filterBloomingDataByPlant, (state, { plant }) => {

    // Add (select) the plant - unless it is already selected (then remove/unselect)
    let plants = state.bloomingDataFilter.plants.find((id) => id === plant?.id)
      ? state.bloomingDataFilter.plants.filter((id) => id !== plant.id)
      : state.bloomingDataFilter.plants.concat(plant?.id);

    // If *all* plants are selected, reset to the empty list
    if (plants?.length === state.bloomingData?.length) {
      plants = [];
    }

    return {
      ...state,
      bloomingDataFilter: {
        ...state.bloomingDataFilter,
        plants
      }
    }
  }),
  on(ResultActions.clearPlantSelection, (state) => ({
    ...state,
    bloomingDataFilter: {
      ...state.bloomingDataFilter,
      plants: [],
    },
  })),
  on(ResultActions.setFilterDateRange, (state, { dateRange }) => ({
    ...state,
    bloomingDataFilter: {
      ...state.bloomingDataFilter,
      dateRange,
      year: dateRange.fromDate ? dateRange.fromDate.getFullYear() : null
    },
  })),

  on(ResultActions.setBaseFilter, (state, props) => {
    return {
      ...state,
      bloomingDataFilter: {
        ...state.bloomingDataFilter,
        ...props.state,
        dateRange: props.state?.year > 0 ? { fromDate: new Date(props.state.year, 0, 1), toDate: new Date(props.state.year, 11, 31) } : {},
        plants: []
      }
    };
  }),

  // Users Toplist
  on(ResultActions.fetchTopList, (state) => ({
    ...state,
    toplistData: [],
    toplistDataLoading: createLoadingNetworkState(),
    toplistDataCacheFlag: false,
  })),
  on(ResultActions.fetchTopListOk, (state, { items }) => ({
    ...state,
    toplistData: items,
    toplistDataLoading: createQuietNetworkState(),
    toplistDataCacheFlag: true,
  })),
  on(ResultActions.fetchTopListFail, (state, action) => ({
    ...state,
    toplistData: [],
    toplistDataLoading: createErrorNetworkState(action.error),
  })),

  // Plants Toplist
  on(ResultActions.fetchTopPlants, (state) => ({
    ...state,
    topplantsData: [],
    topplantsDataLoading: createLoadingNetworkState(),
    topplantsDataCacheFlag: false,
  })),
  on(ResultActions.fetchTopPlantsOk, (state, { items }) => ({
    ...state,
    topplantsData: items,
    topplantsDataLoading: createQuietNetworkState(),
    topplantsDataCacheFlag: true,
  })),
  on(ResultActions.fetchTopPlantsFail, (state, action) => ({
    ...state,
    topplantsData: [],
    topplantsDataLoading: createErrorNetworkState(action.error),
  })),
  on(ResultActions.resetMapPlantFilter, (state) => ({
    ...state,
    bloomingDataFilter: {
      ...state.bloomingDataFilter,
      plants: []
    }
  })),

  // Fetch Spots
  on(ReportActions.fetchSpotsSuccess, (state, { spots }) => ({
    ...state,
    spots
  })),
  on(ReportActions.selectSpot, (state, { spot }) => ({
    ...state,
    activeSpot: spot
  }))
);
