<div>
  <section>
    <nav>
      <a (click)="navClick()" routerLink="/">{{ 'PAGES.TITLES.HOME' | translate }}</a>
      <a (click)="navClick()" routerLink="/plants/list">{{
        'PAGES.TITLES.CATALOGUE' | translate
      }}</a>
      <a *ngIf="features.modes.pollen" (click)="navClick()" routerLink="/plants/find">{{
        'PAGES.TITLES.POLLENID' | translate
      }}</a>
      <a (click)="navClick()" routerLink="/plants/report">{{
        'PAGES.TITLES.REPORT' | translate
      }}</a>
      <a *ngIf="features.modes.pollen" (click)="navClick()" routerLink="/misc/downloads">{{
        'PAGES.TITLES.DOWNLOADS' | translate
      }}</a>
      <a (click)="navClick()" routerLink="/misc/about">{{ 'PAGES.TITLES.ABOUT' | translate }}</a>
      <a (click)="navClick()" routerLink="/user/user">{{ 'MENU.SETTINGS' | translate }}</a>
      <app-language-select></app-language-select>
    </nav>
  </section>
  <section>
    <div class="user">
      <ng-container *ngIf="user; else noUser">
        <a routerLink="/user/user" (click)="navClick()"
          ><mat-icon [inline]="true">person</mat-icon>{{ user?.username }}</a
        >
      </ng-container>
      <ng-template #noUser>
        <a routerLink="/user/signup" (click)="navClick()" mat-raised-button color="accent">{{
          'USER.PAGES.SIGNUP' | translate
        }}</a>
        <a routerLink="/user/login" (click)="navClick()" mat-button>{{
          'USER.BUTTONS.LOGIN' | translate
        }}</a>
      </ng-template>
    </div>
  </section>
  <section class="logo">
    <div>
      <img class="biavl" src="/assets/images/biavl-logo-blue-128.png" />
    </div>
    <div>
      <h4 translate="MENU.ABOUT_HEADER"></h4>
      <p translate="MENU.ABOUT_PARAGRAPH"></p>
      <p>
        <a href="https://promilleafgiftsfonden.dk/" target="_blank">
          <img class="about-logo" src="/assets/images/promilleafgiftsfonden-logo-320.png" />
        </a>
      </p>
      <p>
        <a routerLink="/misc/imprint" (click)="navClick()" translate="FOOTER.IMPRINT"></a>
        <a href="https://biavl.dk" target="_blank" translate="MENU.ABOUT_LINKTEXT"></a>
      </p>
    </div>
  </section>
</div>
